<template>
  <div class="row csn-dh csn-user-account-section" id="user-account">
    <div class="col-md-12">
      <div class="container">
        <section>
          <h1>{{ t('title') }}</h1>
          <p>{{ t('description') }}</p>
        </section>

        <section>
          <h2>{{ t('filter_deposit') }}</h2>
          <Message :type="messageTypeEnum.FAILURE" :message="message" />
          <Form class="form-inline" :ref="formRef">
            <div class="row-fluid">
              <DateField
                :label="t('date_from')"
                :name="name.DATE_FROM"
                v-model="formData[name.DATE_FROM]"
                :validators="[validator.required]"
                :serverError="serverError[name.DATE_FROM]"
              />
              <DateField
                :label="t('date_to')"
                :name="name.DATE_TO"
                v-model="formData[name.DATE_TO]"
                :validators="[validator.required]"
                :serverError="serverError[name.DATE_TO]"
              />
              <div class="form-group">
                <button
                  type="submit"
                  class="btn casino-btn-ver-1 btn"
                  :disabled="isSubmitting"
                  @click="submitForm"
                >
                  <ButtonLoader v-if="isSubmitting" />
                  <span v-else>{{ t('bttn_label') }}</span>
                </button>
              </div>
            </div>
          </Form>
        </section>

        <section v-if="isSubmitting">
          <Loader />
        </section>

        <section v-if="rendersTable">
          <h2>{{ t('showing_results') }}</h2>
          <table>
            <tr>
              <th>{{ t('method_name') }}</th>
              <th>{{ t('start_date') }}</th>
              <th>{{ t('end_date') }}</th>
              <th>{{ t('currency') }}</th>
              <th>{{ t('amount') }}</th>
              <th>{{ t('status') }}</th>
            </tr>

            <tr v-for="(deposit, id) in list" :key="id">
              <td>{{ Deposit.getMethodName(deposit) }}</td>
              <td>{{ Deposit.getCreatedAt(deposit) }}</td>
              <td>{{ Deposit.getUpdatedAt(deposit) }}</td>
              <td>{{ Deposit.getCurrency(deposit) }}</td>
              <td>{{ Deposit.getFormattedPaymentAmount(deposit) }}</td>
              <td>{{ Deposit.getState(deposit) }}</td>
            </tr>
          </table>
          <div class="csn-dh-paginator-container">
            <Paginator v-model="pagination" @getData="getDeposits" />
          </div>
        </section>

        <section v-if="isEmptyList">
          <p class="csn-user-date-empty-list">{{ t('no_deposits') }}</p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { DEPOSIT_HISTORY } from '@/constants'
import * as Deposit from '@/models/getters/deposit'
import { periodFormMixin } from '@/mixins'
import { AuthApi } from '@/api'

export default {
  name: DEPOSIT_HISTORY,
  mixins: [periodFormMixin],
  components: {
    Loader: () => import('@/components/Loader'),
    Form: () => import('@/components/FormData'),
    DateField: () => import('@/components/DateField'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    Message: () => import('@/components/atoms/Message'),
    Paginator: () => import('@/components/Paginator'),
  },
  computed: {
    Deposit: () => Deposit,
    t() {
      return this.$createComponentTranslator(DEPOSIT_HISTORY)
    },
  },
  methods: {
    getDeposits({ ...args }) {
      this.getData({ action: AuthApi.getDeposits, args })
    },
    submitForm() {
      this.$refs[this.formRef].getValidation().isValid && this.getDeposits()
    },
  },
  mounted() {
    this.getDeposits()
  },
}
</script>
