export const Deposit = {
  createdAt: 'createdAt',
  currency: 'currency',
  methodName: 'methodName',
  paymentAmount: 'paymentAmount',
  paymentHoldReason: 'paymentHoldReason',
  providerMethodId: 'providerMethodId',
  providerName: 'providerName',
  state: 'state',
  updatedAt: 'updatedAt',
  uuid: 'uuid',
}

export const DepositModel = (data) => {
  return {
    [Deposit.createdAt]: data?.createdAt,
    [Deposit.currency]: data?.currency,
    [Deposit.methodName]: data?.methodName,
    [Deposit.paymentAmount]: data?.paymentAmount,
    [Deposit.paymentHoldReason]: data?.paymentHoldReason,
    [Deposit.providerMethodId]: data?.providerMethodId,
    [Deposit.providerName]: data?.providerName,
    [Deposit.state]: data?.state,
    [Deposit.updatedAt]: data?.updatedAt,
    [Deposit.uuid]: data?.uuid,
  }
}
