import { prop, pipe, formatNumberLocale } from '@/helpers'
import { Deposit } from '../Deposit'

const getPaymentAmount = prop(Deposit.paymentAmount)

export const getCreatedAt = prop(Deposit.createdAt)
export const getCurrency = prop(Deposit.currency)
export const getMethodName = prop(Deposit.methodName)
export const getPaymentHoldReason = prop(Deposit.paymentHoldReason)
export const getProviderMethodId = prop(Deposit.providerMethodId)
export const getProviderName = prop(Deposit.providerName)
export const getState = prop(Deposit.state)
export const getUpdatedAt = prop(Deposit.updatedAt)
export const getUuid = prop(Deposit.uuid)
export const getFormattedPaymentAmount = pipe(getPaymentAmount, (number) =>
  formatNumberLocale({ number }),
)
